import React, { useState, Fragment, useRef, memo } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import ScheduleButtons from './scheduleButtons';
import UnitButtons from './unitButtons';
import ScheduleUnitButtons from './scheduleUnitButtons';
import DurationButtons from './durationButtons';
import ScheduleInputButtons from './scheduleInputButtons';
import ScheduleQuantityButtons from './scheduleQuantityButtons';
import DurationUnitButtons from './durationUnitButtons';
import {
  QTY_SCHEDULES,
  DURATION_UNITS,
  medicineType,
  MEDICINE_TYPES_SHORTS,
  countOccurrencesOf,
} from '../../../utils/helpers';
import MedicineDefaultNew from '../modals/medicineDefaultNew';
import InstructionTypeHead from './instructionTypeHead';
import { postRequest } from '../../../utils/axiosRequests';
import { useClickAway } from 'react-use';

const EditSelectedMedicineItem = ({
  item,
  itemIndexNo,
  medicineUpdated,
  instructions,
  setInstructions,
  setEditMedicineId,
  handleSelectedMedicine,
}) => {
  let count = itemIndexNo;
  const [detailIndex, setDetailIndex] = useState(0);
  const [isDefault, setIsDefault] = useState(false);
  const [btnNo, setBtnNo] = useState('');
  const [showScheduleBtn, setShowScheduleBtn] = useState(false);
  const [scheduleIndex, setScheduleIndex] = useState('');
  const [showScheduleInputBtn, setShowScheduleInputBtn] = useState(false);
  const [showScheduleQuantityBtn, setShowScheduleQuantityBtn] = useState(false);
  const [showUnitButton, setShowUnitButton] = useState(false);
  const [showDurationBtn, setShowDurationBtn] = useState(false);
  const [showDurationUnitBtn, setShowDurationUnitBtn] = useState(false);
  const [showScheduleUnitBtn, setShowScheduleUnitBtn] = useState(false);
  const [isMedicineDefault, setIsMedicineDefault] = useState(false);
  const [type, setType] = useState('quantity');
  const buttonArray = [1, 2, 3, 4, 5, 6];
  const inputArr = [
    '',
    '0',
    '১',
    '১/৪',
    '১/২',
    '৩/৪',
    '১ ১/২',
    '২',
    '২ ১/২',
    '৩',
    '৪',
    '৫',
    '৬',
    '৭',
  ];

  const getScheduleInputFields = (data, indexNo) => {
    let quantitiesField = data?.quantitiesField;
    let result = quantitiesField.map((field, key) => {
      let quantityValue = data.quantities
        ? data.quantities[QTY_SCHEDULES[key]]
        : 0;

      return (
        <Fragment key={key}>
          <Button
            size="sm"
            className={`medicine-schedule-input-btn ${
              quantityValue != 0 ? 'assigned-value' : ''
            }`}
            variant="outline-secondary"
            onClick={() => handleButtonInput(QTY_SCHEDULES[key], indexNo)}
          >
            <span>{quantityValue ? quantityValue : 0}</span>
            <i className="fa fa-sort" aria-hidden="true"></i>
          </Button>
          {key !== quantitiesField.length - 1 ? <div> + </div> : ''}
        </Fragment>
      );
    });
    return result;
  };

  const setQuantitiesField = (fieldPosition, value, type) => {
    if (type !== 'change') {
      btnFieldClear();
      setShowScheduleInputBtn(false);
    }
    let quantities = item.itemDetails[detailIndex]['quantities'];
    quantities[fieldPosition] = value;
    fieldUpdateWithValue('quantities', quantities);
  };

  const fieldUpdateWithValue = (fieldName, value) => {
    handleSelectedMedicine(fieldName, item.id, value, detailIndex);
  };

  const handleScheduleFieldNumber = (inputFields, itemDetailIndex) => {
    setShowScheduleBtn(false);
    handleSelectedMedicine(
      'quantitiesField',
      item.id,
      inputFields,
      itemDetailIndex,
    );
    let quantities = {};
    inputFields.forEach((field, key) => {
      quantities[QTY_SCHEDULES[key]] = 0;
    });
    handleSelectedMedicine('quantities', item.id, quantities, itemDetailIndex);
  };

  const removeInstruction = (insItem, index) => {
    const result = item?.itemDetails[detailIndex]?.instructions?.filter(
      (instruction) => instruction !== insItem,
    );
    fieldUpdateWithValue('instructionRemove', result);
  };

  const handleDefaultData = (scheduleData) => {
    fieldUpdateWithValue('itemDetails', [scheduleData]);
  };

  const handleButtonInput = (fieldPosition, indexNo) => {
    if (item.itemDetails[indexNo]['quantities'][fieldPosition] === 0) {
      let quantities = item.itemDetails[indexNo]['quantities'];
      item.itemDetails[indexNo]['quantities'] = {
        ...quantities,
        [fieldPosition]: '১',
      };
      fieldUpdateWithValue('quantitiesData', item);
    }
    setScheduleIndex(fieldPosition);
    setShowScheduleInputBtn(showScheduleInputBtn ? false : true);
  };

  const selectedUnit = (changeType, fieldName, val) => {
    fieldUpdateWithValue(fieldName, val);
    if (changeType === 'select' || changeType === 'clickOutside') {
      btnFieldClear();
    }
  };

  const unitArrayData = (type) => {
    let result = [];
    if (type === 'quantityUnit') {
      // don not delete this code because it will be used in future
      // if (TAB_CAP_ARRAY.indexOf(convertToLower(item?.type)) !== -1) {
      //   result = ["টা", "টি"];
      // } else if (
      //   POWDER_SYRUP_ARRAY.indexOf(convertToLower(item?.type)) === -1
      // ) {
      //   result = [
      //     "টা",
      //     "টি",
      //     "চামুচ",
      //     "চা চামুচ",
      //     "বার",
      //     "চাপ",
      //     "পাফ",
      //     "স্প্রে",
      //     "লাগাবেন",
      //     "ফোটা",
      //     "স্টিক",

      //   ];
      // } else if (IV_INFUSION_ARRAY.indexOf(convertToLower(item?.type)) !== -1) {
      //   result = ["এম্পুল", "ml", "Unit", "বার"];
      // }
      if (medicineType(item?.type)) {
        result = ['', 'টা', 'টি', 'এম্পুল', 'Vial'];
      } else {
        result = [
          '',
          'টা',
          'টি',
          'চামুচ',
          'চা চামুচ',
          'বার',
          'চাপ',
          'পাফ',
          'স্প্রে',
          'লাগাবেন',
          'ফোটা',
          'স্টিক',
          'এম্পুল',
          'মিলি',
          'ml',
          'Vial',
        ];
      }
    } else if (type === 'scheduleUnit') {
      result = ['', 'বার', 'বেলা', 'ঘন্টা পরপর'];
    } else if (type === 'duration') {
      result = ['', '১', '২', '৩', '৫', '৭', '১০', '১৫', '২০', '৩০'];
    }
    return result;
  };

  const toggleUnitButton = (type, index) => {
    btnFieldClear();
    if (type === 'quantity') {
      setShowUnitButton(showUnitButton ? false : true);
    } else if (type === 'schedule') {
      setShowScheduleUnitBtn(showScheduleUnitBtn ? false : true);
    } else if (type === 'duration') {
      setShowDurationBtn(showDurationBtn ? false : true);
    } else if (type === 'durationUnit') {
      setShowDurationUnitBtn(showDurationUnitBtn ? false : true);
    }
  };

  const actionOnClick = (fieldName, value, changeType = '') => {
    fieldUpdateWithValue(fieldName, value);
    if (changeType !== 'change') {
      btnFieldClear();
    }
  };

  const btnFieldClear = () => {
    setShowUnitButton(false);
    setShowDurationBtn(false);
    setShowScheduleInputBtn(false);
    setShowScheduleUnitBtn(false);
    setShowScheduleQuantityBtn(false);
    setShowDurationUnitBtn(false);
  };

  const updateOnClickActionBtn = (type) => {
    setShowScheduleQuantityBtn(showScheduleQuantityBtn ? false : true);
    setType(type);
  };

  const makeDefault = () => {
    postRequest('medicine-default', {
      itemDetails: [item.itemDetails[0]],
      medicineId: item?.id,
      isDefault: true,
    })
      .then((data) => {
        setIsDefault(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const duplicateSchedule = () => {
    let newSchedule = {
      quantitiesField: [1, 2, 3],
      schedule: '',
      scheduleUnit: 'বার',
      quantities: { first: 0, second: 0, third: 0 },
      quantity: '',
      quantityUnit: medicineType(item?.type) ? 'টা' : 'চামুচ',
      times: '১',
      timesUnit: 'বেলা',
      duration: '',
      durationUnit: 'দিন',
      instructions: [],
    };
    item['itemDetails'] = [...item.itemDetails, newSchedule];
    medicineUpdated(item, itemIndexNo);
  };

  const removeSchedule = (index) => {
    item.itemDetails = item.itemDetails.filter((item, key) => {
      return index !== key;
    });
    medicineUpdated(item, itemIndexNo);
  };

  const handleScheduleBtnNumber = (index) => {
    setShowScheduleBtn(index);
  };

  const handleDetailIndex = (index) => {
    setDetailIndex(index);
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    setShowScheduleBtn(false);
    setShowScheduleInputBtn(false);
    setShowScheduleQuantityBtn(false);
    setShowScheduleUnitBtn(false);
    setShowUnitButton(false);
    setShowDurationBtn(false);
    setShowDurationUnitBtn(false);
  });

  const selectedList = () => {
    return item?.itemDetails?.map((data, index) => {
      return (
        <div
          style={index > 0 ? { borderTop: '1px solid #fff' } : {}}
          onClick={() => handleDetailIndex(index)}
          key={index}
        >
          <Row className="header-row">
            <Col md={5}>
              {medicineType(item.type) ? (
                <span onClick={() => handleScheduleBtnNumber(index)}>
                  Schedule <i className="fa fa-angle-down"></i>
                </span>
              ) : (
                <span>Quantity</span>
              )}
            </Col>
            <Col md={3}>
              <span>{medicineType(item.type) ? 'Unit' : 'Schedule'}</span>
            </Col>
            <Col md={3}>Duration</Col>
          </Row>
          <Row className="mb-1">
            {medicineType(item.type) && (
              <Col md={5} ref={ref} className="text-center">
                {showScheduleBtn === index && (
                  <ScheduleButtons
                    itemDetailIndex={index}
                    buttonArray={buttonArray}
                    actionMethod={handleScheduleFieldNumber}
                  />
                )}
                <div className="common-input-fields">
                  {getScheduleInputFields(data, index)}
                </div>
              </Col>
            )}
            {!medicineType(item.type) && (
              <Col md={5} className="common-input-fields">
                <Button
                  size="sm"
                  className={`medicine-schedule-input-btn mr-1 ${
                    data?.quantity != 0 ? 'assigned-value' : ''
                  }`}
                  variant="outline-secondary"
                  onClick={() => updateOnClickActionBtn('quantity')}
                >
                  <span>{data.quantity}</span>
                  <i className="fa fa-sort" aria-hidden="true"></i>
                </Button>
                <Button
                  size="sm"
                  className={`medicine-schedule-input-btn mr-1 ${
                    data?.quantityUnit !== '' ? 'assigned-value' : ''
                  }`}
                  variant="outline-secondary"
                  onClick={() => {
                    toggleUnitButton('quantity');
                    setBtnNo(1);
                  }}
                >
                  <span className="medicine-text">{data.quantityUnit}</span>
                  <i className="fa fa-sort" aria-hidden="true"></i>
                </Button>
              </Col>
            )}
            {medicineType(item.type) && (
              <Col md={3} className="common-input-fields">
                <Button
                  size="sm"
                  className={`medicine-schedule-input-btn ${
                    data?.quantityUnit ? 'assigned-value' : ''
                  }`}
                  variant="outline-secondary"
                  onClick={() => {
                    toggleUnitButton('quantity');
                    setBtnNo('');
                  }}
                >
                  <span>{data.quantityUnit}</span>
                  <i className="fa fa-sort" aria-hidden="true"></i>
                </Button>
              </Col>
            )}
            {!medicineType(item.type) && (
              <Col md={3} className="common-input-fields">
                <Button
                  size="sm"
                  className={`medicine-schedule-input-btn ${
                    data?.schedule != '' ? 'assigned-value' : ''
                  }`}
                  variant="outline-secondary"
                  onClick={() => updateOnClickActionBtn('schedule')}
                >
                  <span>{data.schedule}</span>
                  <i className="fa fa-sort" aria-hidden="true"></i>
                </Button>
                <Button
                  size="sm"
                  className={`medicine-schedule-input-btn ${
                    data?.scheduleUnit != '' ? 'assigned-value' : ''
                  }`}
                  variant="outline-secondary"
                  onClick={() => toggleUnitButton('schedule')}
                >
                  <span className="medicine-text">{data.scheduleUnit}</span>
                  <i className="fa fa-sort" aria-hidden="true"></i>
                </Button>
              </Col>
            )}

            <Col md={3} className="common-input-fields">
              <Button
                size="sm"
                className={`medicine-schedule-input-btn ${
                  data?.duration != '' ? 'assigned-value' : ''
                }`}
                variant="outline-secondary"
                onClick={() => toggleUnitButton('duration')}
                disabled={item.durationUnit === 'চলবে' ? true : false}
              >
                <span>{data.duration}</span>
                <i className="fa fa-sort" aria-hidden="true"></i>
              </Button>
              <Button
                size="sm"
                className={`medicine-schedule-input-btn ${
                  data?.durationUnit !== '' ? 'assigned-value' : ''
                }`}
                variant="outline-secondary"
                onClick={() => toggleUnitButton('durationUnit')}
              >
                <span>{data.durationUnit}</span>
                <i className="fa fa-sort" aria-hidden="true"></i>
              </Button>
            </Col>
            <Col className="text-center">
              {index > 0 && (
                <i
                  className="fa fa-times-circle"
                  onClick={() => removeSchedule(index)}
                  aria-hidden="true"
                ></i>
              )}
            </Col>
          </Row>
          {showScheduleQuantityBtn && detailIndex === index && (
            <Row>
              <Col ref={ref}>
                <ScheduleQuantityButtons
                  inputArr={inputArr}
                  actionOnClick={actionOnClick}
                  type={type}
                />
              </Col>
            </Row>
          )}
          {showScheduleUnitBtn && detailIndex === index && (
            <Row>
              <Col md={5}></Col>
              <Col ref={ref}>
                <ScheduleUnitButtons
                  unitArray={unitArrayData('scheduleUnit')}
                  selectedUnit={selectedUnit}
                />
              </Col>
            </Row>
          )}
          {showScheduleInputBtn && detailIndex === index && (
            <Row>
              <Col ref={ref}>
                <ScheduleInputButtons
                  inputArr={inputArr}
                  setQuantitiesField={setQuantitiesField}
                  scheduleIndex={scheduleIndex}
                />
              </Col>
            </Row>
          )}
          {showUnitButton && detailIndex === index && (
            <Row>
              {btnNo !== 1 && <Col md={5}></Col>}
              <Col ref={ref}>
                <UnitButtons
                  unitArray={unitArrayData('quantityUnit')}
                  selectedUnit={selectedUnit}
                />
              </Col>
            </Row>
          )}
          {detailIndex === index && showDurationBtn && (
            <Row>
              <Col md={5}></Col>
              <Col ref={ref}>
                <DurationButtons
                  unitArray={unitArrayData('duration')}
                  selectedUnit={selectedUnit}
                />
              </Col>
            </Row>
          )}
          {showDurationUnitBtn && detailIndex === index && (
            <Row>
              <Col md={7}></Col>
              <Col ref={ref}>
                <DurationUnitButtons
                  inputArr={DURATION_UNITS}
                  actionOnClick={actionOnClick}
                  type={type}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-2 mb-1">
            <Col md={7}>
              {item?.itemDetails[index]?.instructions?.map((insItem, index) => (
                <Button
                  key={index}
                  variant="primary"
                  className="btn btn-sm mr-2 mb-1 customize-btn ins-btn"
                  onClick={() => removeInstruction(insItem, index)}
                >
                  {insItem}
                </Button>
              ))}
            </Col>
            <Col
              md={5}
              className="medicine-remarks"
              style={{ position: 'static' }}
            >
              <InstructionTypeHead
                index={index}
                item={item}
                detailIndex={detailIndex}
                instructions={instructions}
                setInstructions={setInstructions}
                fieldUpdateWithValue={fieldUpdateWithValue}
              />
            </Col>
          </Row>
        </div>
      );
    });
  };

  return (
    <div className="selected-medicine-item selected-medicine-edit">
      <Row className="ml-0 mr-0">
        <Col md={7} style={{ whiteSpace: 'nowrap' }}>
          {++count}. {MEDICINE_TYPES_SHORTS[item.type]} {item.name}{' '}
          {`${
            item.strength && countOccurrencesOf(item.strength, '+') <= 1
              ? ' (' + item.strength + ')'
              : ''
          }`}{' '}
          <span
            className="icon-area"
            onClick={() => setIsMedicineDefault(true)}
          >
            <i
              className="fa fa-bars"
              style={{
                paddingLeft: '7px',
                fontSize: '12px',
                cursor: 'pointer',
              }}
            ></i>
          </span>
          <span className="icon-area" onClick={() => duplicateSchedule()}>
            <i className="fa fa-copy" title="Multiple Medicine Schedule"></i>
          </span>
          <span className="icon-area" onClick={() => makeDefault()}>
            <i
              className={`fa fa-flag ${
                isDefault || item?.isDefaultSchedule
                  ? 'flag-active'
                  : 'flag-inactive'
              }`}
              title="Make default"
            ></i>
          </span>
        </Col>
        <Col className="text-right">
          <i
            className="fa fa-times-circle"
            onClick={() => setEditMedicineId('')}
            aria-hidden="true"
          ></i>
        </Col>
      </Row>
      {selectedList()}
      {isMedicineDefault && (
        <MedicineDefaultNew
          item={item}
          removeSchedule={removeSchedule}
          duplicateSchedule={duplicateSchedule}
          handleDefaultData={handleDefaultData}
          isTabCabType={medicineType(item.type)}
          setIsMedicineDefault={setIsMedicineDefault}
          handleSelectedMedicine={handleSelectedMedicine}
        />
      )}
    </div>
  );
};

export default memo(EditSelectedMedicineItem);
